// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Image,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import { useLocation } from "react-router-dom";
import { getVariable } from "whitelabel";

export default function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  brandLink: string;
  namePrevious: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const loc = useLocation();

  const { secondary, brandText, brandLink, namePrevious } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed" as const;
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );

  let navbarBorder = "transparent";
  let secondaryMargin = "var(--safe-area-inset-top)";
  let paddingX = "10px";
  let gap = "0px";

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="65px"
      maxH={"65px"}
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top={{ base: "12px", md: "14px", xl: "15px" }}
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
    >
      <Flex w="100%" flexDirection="row" alignItems={{ xl: "center" }} mb={gap}>
        {brandText.includes("Dashboard") ? (
          <Box width={"90%"} mb={{ sm: "8px", md: "0px" }}>
            <Image
              src={getVariable("logo")}
              width="180px"
              alt={props.logoText}
            />
          </Box>
        ) : (
          <Box width={"90%"} mb={{ sm: "8px", md: "0px" }}>
            <Breadcrumb>
              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <BreadcrumbLink href="/#/access/" color={secondaryText}>
                  Início
                </BreadcrumbLink>
              </BreadcrumbItem>
              {namePrevious === brandText ? null : (
                <BreadcrumbItem color={secondaryText} fontSize="sm">
                  <BreadcrumbLink
                    href={brandLink || "/#/access/"}
                    color={secondaryText}
                  >
                    {namePrevious}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
            </Breadcrumb>

            <Link
              color={mainText}
              href={`/#${loc.pathname}` || "/#/access/"}
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="30px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {brandText}
            </Link>
          </Box>
        )}
        <Box ms="auto" w={{ sm: "10%", md: "unset" }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Box>
  );
}
