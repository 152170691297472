import { ChargeStatus, CreditCardStatus } from "views/access/charges";

export interface InstantQrCode {
  id: string;
  qrCode: string;
  txId: string;
  expirationDate: string;
  integrationId: string;
  amount: number;
  clientId: string;
  status: string;
  expireAt: string;
  createdAt: string;
  chargeType: string;
  description: string;
  person: null;
  link: string;
}

export enum ChargeType {
  PIX = "Pix",
  BOLETO = "Boleto",
  CREDIT_CARD = "Cartão de Crédito",
  DEBIT_CARD = "Cartão de Débito",
  INVOICE = "Cobrança",
}

export interface Charge {
  id: string;
  sequence: number;
  customerId: string;
  clientId: string;
  referenceId: string;
  chargeType: string;
  status: ChargeStatus;
  amount: string;
  amountRefunded: string;
  description: string;
  paymentId: any;
  urlPayment: any;
  callbackUrl: string;
  devedorName: string;
  devedorCpfCnpj: string;
  createdAt: string;
  updatedAt: string;
  payedAt?: string;
  expireAt?: string;
  Pix: Pix[];
  Billet: Billet[];
  Movements: any[];
  CustomerClient: Customer;
  CreditCardPayment: CreditCardInfo[];
  webhook?: WebhookCharge[];
  person: ChargePerson;
  shipping: ChargeShipping;
  itens: ChargeItens[];
}

export interface ChargeShipping {
  id: string;
  chargeId: string;
  street: string;
  number: string;
  city: string;
  complement: string;
  district: string;
  state: string;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
}

export interface ChargeItens {
  id: string;
  chargeId: string;
  amount: string;
  name: string;
  quantity: number;
  unitPrice: string;
  feePercent: string;
}

export interface ChargePerson {
  id: string;
  chargeId: string;
  email: string;
  document: string;
  name: string;
  ip: string;
  createdAt: string;
  updatedAt: string;
}

export interface WebhookCharge {
  id: string;
  chargeId: string;
  url: string;
  customerId: string;
  payload: Record<string, any>;
  status: number;
  createdAt: string;
  updatedAt: string;
}

export interface CreditCardInfo {
  id: string;
  customerId: string;
  chargeId: string;
  movementId: string;
  integrationId: string;
  type: string;
  amount: string;
  amountRefunded: string;
  status: CreditCardStatus;
  paymentId: string;
  paymentMessage: string;
  tId: string;
  nsu: string;
  eci: any;
  returnCode: string;
  returnMessage: string;
  proofOfSale: string;
  authorizationCode: string;
  jsonResponse: object;
  creditCard: string;
  brand: string;
  expiration: string;
  installments: number;
  createdAt: string;
  updatedAt: string;
}

export interface Billet {
  id: string;
  billetId: string;
  integrationId: string;
  chargeId: string;
  amount: string;
  amountPaid: string;
  status: ItemChargeStatus;
  dueDate: any;
  billetAt: string;
  billetCode: string;
  billetUrl: any;
  billetDigit: string;
  billetLine: string;
  billetType: string;
  billetValue: string;
  billetUrlQr: any;
  billetPdf: string;
  createdAt: string;
  updatedAt: string;
}
export interface Pix {
  id: string;
  chargeId: string;
  integrationId: string;
  referenceIntegrationId: string;
  qrCode: string;
  amount: string;
  status: any;
  endToEndId: any;
  createdAt: string;
  updatedAt: string;
}

export enum ItemChargeStatus {
  PENDING = "PENDING",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
}

export enum PixStatus {
  PENDING = "Pendente",
  FINISHED = "Finalizado",
  CANCELED = "Cancelado",
  REFUNDED = "Devolvido",
}

export enum BilletStatus {
  PENDING = "Pendente",
  FINISHED = "Finalizado",
  REFUNDED = "Devolvido",
  CANCELED = "Cancelado",
}

export interface Customer {
  id: string;
  status: number;
  email: string;
  companyId: string;
  password: string;
  document_number: string;
  apple_id: any;
  name: string;
  webhookUrl: any;
  balance: string;
  mainCustomerId: any;
  deletedAt: any;
  createdAt: string;
  updatedAt: string;
}
