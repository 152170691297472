import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import RecoverPasswordForm from "views/access/customers/components/RecoverPassCard";

const ForgetPassword: React.FC<{}> = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Button
        onClick={onOpen}
        size="xs"
        rounded="sm"
        variant="link"
        color="brand.900"
        ref={btnRef}
      >
        Esqueci minha senha
      </Button>
      {isOpen && (
        <Drawer
          finalFocusRef={btnRef}
          isOpen={isOpen}
          onClose={onClose}
          placement="bottom"
        >
          <DrawerOverlay />
          <DrawerContent pb="var(--safe-area-inset-bottom)">
            <DrawerHeader>Esqueci minha senha</DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody pb={5}>
              <RecoverPasswordForm onCloseModal={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default ForgetPassword;
