// Chakra imports
import { Flex } from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { getVariable } from "whitelabel";
import FirstPage from "./firstPage";
import RecaptchaAlert from "../signIn/RecaptchaAlert";

function Register() {
  return (
    <DefaultAuth
      illustrationBackground={getVariable("banner")}
      image={getVariable("banner")}
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
        useEnterprise={true}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "body", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          my={{ base: "auto", lg: "150" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "0", md: "4vh" }}
          flexDirection="column"
        >
          <FirstPage />
          <RecaptchaAlert />
        </Flex>
      </GoogleReCaptchaProvider>
    </DefaultAuth>
  );
}

export default Register;
