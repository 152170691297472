import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import React, { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { BsBuilding } from "react-icons/bs";
import { startOnboarding } from "services/api.service";
import { FaPerson } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

const FirstPage: React.FC = () => {
  const textColor = useColorModeValue("brand.700", "white");
  const textColorSecondary = "gray.400";
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (type = "ONBOARDING_CUSTOMER") => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      return await executeRecaptcha(type);
      // Do whatever you want with the token
    },
    [executeRecaptcha]
  );
  const { isLoading, mutateAsync: startOnboardingRequest } = useMutation(
    ["startOnboarding"],
    startOnboarding
  );

  const handleSubmitType = async (type: "PF" | "PJ") => {
    const captchaToken = await handleReCaptchaVerify();
    await startOnboardingRequest({ type, captchaToken }).then((response) => {
      if (type === "PF") {
        history.push(`/auth/register/holder?id=${response.id}`, {
          id: response.id,
        });
      } else {
        history.push(`/auth/register/company?id=${response.id}`);
      }
    });
  };

  return (
    <Box w="full" alignItems="center" display="flex" flexDirection="column">
      <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
        Cadastre-se!
      </Heading>
      <Text
        mb="36px"
        ms="4px"
        color={textColorSecondary}
        fontWeight="400"
        fontSize="md"
      >
        Selecione o tipo de cadastro abaixo!
      </Text>
      <VStack w="100%" spacing={4} height="100%" mb={"200px"}>
        <CustomButton
          leftIcon={<FaPerson />}
          variant="solid"
          isLoading={isLoading}
          onClick={() => handleSubmitType("PF")}
        >
          Pessoa Física
        </CustomButton>
        <CustomButton
          leftIcon={<BsBuilding />}
          variant="solid"
          isLoading={isLoading}
          onClick={() => handleSubmitType("PJ")}
        >
          Pessoa Jurídica
        </CustomButton>
      </VStack>
    </Box>
  );
};

export default FirstPage;
