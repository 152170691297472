import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { findWithdrawel } from "services/api.service";
import { maskDocument } from "utils/formatters";
import { maskCurrency } from "utils/number";
import { Withdrawal } from "..";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { useReactToPrint } from "react-to-print";
import PrintWithdrawel from "./PrintWithdrawel";
import { FiPrinter } from "react-icons/fi";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { toPng } from "html-to-image";
import { shareFileBase64 } from "utils/saver";

const LabelItem: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <VStack mb={3} width="100%" alignItems="start">
      <Text as={"small"} fontSize="sm" color="gray.600">
        {label}
      </Text>
      <Text
        mt={"0px !important"}
        width="100%"
        fontSize="md"
        overflow="hidden"
        noOfLines={30}
      >
        {value || "---"}
      </Text>
    </VStack>
  );
};

const SeeWithdrawel: React.FC<{
  data: Withdrawal;
}> = ({ data: dataWithdraw }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [data, setData] = useState(dataWithdraw);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const generatePDFBlob = async () => {
    const png = await toPng(componentRef.current, {
      pixelRatio: 2,
      backgroundColor: "white",
      quality: 1,
    });
    shareFileBase64("comprovante.png", png);
  };

  return (
    <>
      <IconButton
        aria-label="Ver Saque"
        rounded="md"
        size="sm"
        variant="outline"
        icon={<FaEye />}
        onClick={() => {
          findWithdrawel(data.id).then((res) => {
            setData(res);
          });
          onOpen();
        }}
      />
      {isOpen && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Verificar Saque</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VisuallyHidden>
                  <PrintWithdrawel data={data} ref={componentRef} />
                </VisuallyHidden>
                <SimpleGrid columns={{ base: 1 }}>
                  <LabelItem label="Cliente" value={data.Customer?.name} />
                  <LabelItem
                    label="Documento"
                    value={maskDocument(data.Customer?.document_number)}
                  />
                  <LabelItem
                    label="Valor do Saque"
                    value={maskCurrency(data.amount)}
                  />{" "}
                  <LabelItem
                    label="Valor da Taxa"
                    value={maskCurrency(data.fee)}
                  />{" "}
                  <LabelItem
                    label="Valor Final"
                    value={maskCurrency(
                      Math.abs(+data.amount) + Math.abs(+data.fee)
                    )}
                  />
                  <LabelItem label="Descrição" value={data.description} />
                </SimpleGrid>

                {data.type === "BILLET" ? (
                  <>
                    <Text fontWeight="semibold">Dados Boleto</Text>
                    <Divider mb={2} />
                    <SimpleGrid columns={{ base: 1 }}>
                      <LabelItem
                        label="Data de Vencimento"
                        value={
                          data.dueDate
                            ? DateTime.fromISO(data.dueDate).toFormat(
                                "dd/MM/yyyy"
                              )
                            : "---"
                        }
                      />
                      <LabelItem label="Boleto" value={data.billetCode} />
                      <Button
                        colorScheme="green"
                        size={"sm"}
                        variant={"outline"}
                        onClick={() => {
                          navigator.clipboard.writeText(data.billetCode);
                          toast({
                            title: "Sucesso",
                            description: `Boleto copiado com sucesso`,
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                        }}
                      >
                        Copiar
                      </Button>
                    </SimpleGrid>
                  </>
                ) : undefined}
                {data.type === "QRCODE" ? (
                  <Box maxW="100%">
                    <Text fontWeight="semibold">Pix Copia e Cola</Text>
                    <Divider mb={2} />
                    <SimpleGrid columns={{ base: 1 }}>
                      <LabelItem label="Copia e Cola" value={data.billetCode} />
                    </SimpleGrid>
                  </Box>
                ) : undefined}
                {data.type === "DICT" ? (
                  <>
                    <Text fontWeight="semibold">Dados bancários</Text>
                    <Divider mb={2} />
                    <SimpleGrid columns={{ base: 1 }}>
                      <>
                        {" "}
                        <LabelItem
                          label="Chave Pix"
                          value={data.CustomerAccounts?.pix}
                        />
                      </>
                    </SimpleGrid>
                  </>
                ) : undefined}
                {data.CustomerAccounts?.bankIspb && (
                  <>
                    <LabelItem
                      label="Banco"
                      value={data.CustomerAccounts?.bankIspb}
                    />
                    <LabelItem
                      label="Agência"
                      value={data.CustomerAccounts?.agency}
                    />
                    <LabelItem
                      label="Conta"
                      value={data.CustomerAccounts?.account}
                    />

                    <LabelItem
                      label="Nome Recebedor"
                      value={data.CustomerAccounts?.accountHolder}
                    />
                    <LabelItem
                      label="Documento recebedor"
                      value={data.CustomerAccounts?.accountHolderDocument}
                    />
                  </>
                )}
                {data?.withdrawSulcredi && (
                  <Box mt={4} border="ButtonShadow">
                    <Text fontWeight="semibold" mb={4}>
                      Comprovante
                    </Text>
                    <Tooltip label={`Imprimir`} placement={`top`}>
                      <IconButton
                        aria-label="Imprimir"
                        icon={<FiPrinter />}
                        size={`sm`}
                        color={`gray.500`}
                        variant={`outline`}
                        onClick={
                          !Capacitor.isNativePlatform()
                            ? handlePrint
                            : () => generatePDFBlob()
                        }
                        disabled={data?.withdrawSulcredi.status !== 2}
                      />
                    </Tooltip>
                    <LabelItem
                      label="Status Pagamento"
                      value={
                        data?.withdrawSulcredi.status === 2
                          ? "Pago"
                          : "Pendente"
                      }
                    />
                    <LabelItem
                      label="Id de Pagamento"
                      value={data?.withdrawSulcredi.endToEndId}
                    />
                    <LabelItem
                      label="Pago em"
                      value={DateTime.fromISO(
                        data?.withdrawSulcredi.updatedAt
                      ).toFormat("dd/MM/yyyy HH:mm:ss")}
                    />
                  </Box>
                )}
                {data.imageUrl && !Capacitor.isNativePlatform() && (
                  <Box mt={4}>
                    <Text fontWeight="semibold">Download do comprovante</Text>
                    <Divider mb={2} />
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        saveAs(data.imageUrl);
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                )}
                {data.imageUrl && Capacitor.isNativePlatform() && (
                  <Box mt={4}>
                    <Text fontWeight="semibold">Download do comprovante</Text>
                    <Divider mb={2} />
                    <Button
                      colorScheme="green"
                      onClick={async () => {
                        try {
                          await Share.share({
                            title: "Compartilhar comprovante",
                            url: data.imageUrl,
                          });
                        } catch (error) {
                          console.error("Error sharing image:", error);
                        }
                      }}
                    >
                      Comparilhar
                    </Button>
                  </Box>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default SeeWithdrawel;
