// Chakra imports
import { Box, ButtonGroup, SimpleGrid } from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllBalances } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { maskCurrency } from "utils/number";
import ShowBalance from "./components/ShowBalance";
import { BsCheckLg } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { getVariable } from "whitelabel";
import PullToRefresh from "react-simple-pull-to-refresh";
import { queryClient } from "index";

export interface Balance {
  amount: string;
  amountRefunded: string;
  createdAt: Date;
  id: string;
  fee: string;
  released: boolean;
  releasedOn?: string;
  type: string;
  referenceId: string;
  description?: string;
  Customer: {
    id: string;
    name: string;
  };
}

export const TypeRender = (type: string) => {
  if (type === "LOCK") return "Bloqueio";
  if (type === "FEE") return "Taxa";
  if (type === "MOVEMENT") return "Venda";
  if (type === "CARD_RECHARGE") return "Recarga de Cartão";
  if (type === "MOVEMENT_MAIN") return "Venda Cliente";
  if (type === "WITHDRAWAL") return "Saque";
  if (type === "SYSTEM_FEE") return "Taxa " + getVariable("name");
  if (type === "SPLIT") return "Split";
  if (type === "CUSTOMER_SPLIT") return "Split do Cliente";
  if (type === "WITHDRAWAL_ANTECIPATION") return "Antecipação de Recebíveis";
  if (type === "TRANSFER") return "Transferência";
};

export default function Balances() {
  const columns = useMemo(
    () =>
      [
        {
          Header: "Tipo",
          accessor: "type",
          Cell: ({ value }) => {
            return TypeRender(value);
          },
        },
        {
          Header: "Valor Líquido",
          accessor: "amount",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Valor Devolvido",
          accessor: "amountRefunded",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Taxa/Liberado",
          accessor: "fee",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },

        {
          Header: "Liberado",
          accessor: "released",
          Cell: ({ value }) => {
            return value ? (
              <BsCheckLg color="green.500" />
            ) : (
              <GrClose color="red.500" />
            );
          },
        },
        {
          accessor: "releasedOn",
          Header: "Data de Liberação",
          Cell: ({ value, row }: any) => {
            return !value
              ? "---"
              : DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de Cadastro",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: Balance) => (
    <ButtonGroup>
      <ShowBalance dado={row} />
    </ButtonGroup>
  );

  const handleRefresh = async () => {
    await queryClient.invalidateQueries({ queryKey: ["findAllBalances"] });
  };

  // Chakra Color Mode
  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <Box>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <TableComponent
            label="Saldos liberados"
            columnsData={columns}
            filterable={["customerId", "createdAt"]}
            right={<></>}
            queryFn={findAllBalances}
            queryKey="findAllBalances"
            actions={actions}
          />
        </SimpleGrid>
      </Box>
    </PullToRefresh>
  );
}
