import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  VStack,
  Text,
  Card,
  CardBody,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { maskCurrency } from "utils/number";
import MiniStatistics from "./card/MiniStatistics";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || (defaultRef as any);

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <Checkbox ref={resolvedRef} {...rest} />;
  }
);

export default function MobileList(props: {
  columnsData: any[];
  itensData: any[];
  actions: any;
  pageCount?: number;
  setFilter: any;
  enableSelect?: boolean;
  setSelectedRows?: any;
  aggregateSelection?: {
    key: string;
    label: string;
  }[];
}) {
  const {
    columnsData,
    itensData,
    actions,
    pageCount,
    setFilter,
    enableSelect,
    setSelectedRows,
    aggregateSelection,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const tableData = useMemo(() => itensData, [itensData]);

  const tableInstance = useTable(
    {
      columns,
      manualPagination: true,
      manualSortBy: true,
      initialState: { pageIndex: 0, pageSize: 10 },
      data: tableData || [],
      autoResetPage: false,
      pageCount: pageCount || 0,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      enableSelect &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: any) => (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                isChecked={row.isSelected}
              />
            ),
          },
          ...columns,
        ]);
    }
  );

  const {
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = tableInstance;

  useEffect(() => {
    setFilter((prev: any) => ({
      ...prev,
      pageIndex,
      pageSize,
      orderBy: sortBy,
    }));
  }, [pageIndex, pageSize, sortBy, setFilter]);

  useEffect(() => {
    setSelectedRows &&
      setSelectedRows(
        tableData
          .map((item: any, i) => {
            if (selectedRowIds[i]) {
              return item.id;
            }
            return null;
          })
          .filter((item: any) => item !== null)
      );
  }, [selectedRowIds, setSelectedRows, tableData]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardBg = useColorModeValue("white", "navy.700");

  return (
    <Box>
      {/* Aggregate Statistics */}
      <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4} mb={4}>
        {aggregateSelection?.map(({ key, label }) => (
          <MiniStatistics
            key={key}
            name={label}
            value={maskCurrency(
              tableData
                .map((item: any, i) => (selectedRowIds[i] ? item : null))
                .filter((item: any) => item !== null)
                .reduce((acc, cur) => acc + Math.abs(+cur[key]), 0) || 0
            )}
          />
        ))}
      </SimpleGrid>

      {/* Empty State */}
      {tableData?.length === 0 && (
        <Flex justify="center" align="center" h="200px" color="gray.500">
          <Text>Nenhum registro encontrado</Text>
        </Flex>
      )}

      {/* Card List */}
      <VStack spacing={4} mb={4}>
        {page.map((row, idx) => {
          prepareRow(row);
          return (
            <Card
              key={idx}
              w="full"
              bg={cardBg}
              boxShadow="sm"
              borderRadius="lg"
            >
              <CardBody>
                <VStack align="stretch" spacing={2}>
                  {enableSelect && (
                    <Box>
                      {row.cells
                        .filter((cell) => cell.column.id === "selection")
                        .map((cell) => cell.render("Cell"))}
                    </Box>
                  )}
                  {row.cells
                    .filter(
                      (cell) =>
                        cell.column.id !== "selection" &&
                        cell.column.id !== "actions"
                    )
                    .map((cell, cellIdx) => (
                      <Flex key={cellIdx} justify="space-between">
                        <Text color="gray.500" fontSize="sm">
                          {cell.column.Header as string}:
                        </Text>
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.render("Cell")}
                        </Text>
                      </Flex>
                    ))}
                  {actions && (
                    <HStack justify="flex-end" pt={2}>
                      {actions(row.original)}
                    </HStack>
                  )}
                </VStack>
              </CardBody>
            </Card>
          );
        })}
      </VStack>

      {/* Pagination */}
      <Flex justify="center" direction="column" align="center" w="full">
        <HStack spacing={2} mb={4}>
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            size="sm"
            variant="outline"
          >
            {"<<"}
          </Button>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            size="sm"
            variant="outline"
          >
            {"<"}
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            size="sm"
            variant="outline"
          >
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            size="sm"
            variant="outline"
          >
            {">>"}
          </Button>
        </HStack>

        <VStack spacing={2}>
          <Text fontSize="sm">
            Página {pageIndex + 1} de {pageOptions.length}
          </Text>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            style={{ padding: "4px 8px", borderRadius: "4px" }}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Exibir {size}
              </option>
            ))}
          </select>
        </VStack>
      </Flex>
    </Box>
  );
}
