// Chakra imports
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { getVariable } from "whitelabel";
import InputMaskForm from "components/InputMask";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { validCnpj } from "services/validDocument";
import { onlyNumbers } from "utils/string";
import { useEffect, useState } from "react";
import InputForm from "components/InputForm";
import CustomButton from "components/CustomButton";
import {
  findOnboarding,
  NextOnboarding,
  nextOnboarding,
} from "services/api.service";
import { useMutation } from "@tanstack/react-query";

function CompanyRegister() {
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const id = params.get("id");
  const history = useHistory();

  const textColor = useColorModeValue("brand.700", "white");
  const toast = useToast();
  const [validatedCnpj, setValidatedCnpj] = useState(false);
  const { control, handleSubmit, setValue } = useForm<{
    document: string;
    name: string;
    email: string;
    phone: string;
    secondName: string;
  }>();

  const { isLoading, mutateAsync } = useMutation([], () => findOnboarding(id));

  useEffect(() => {
    mutateAsync().then((response) => {
      if (response.document) {
        setValidatedCnpj(true);
        setValue("document", response.document);
        setValue("name", response.name);
        setValue("secondName", response.secondName);
        setValue("email", response.email);
        setValue("phone", response.phone);
      }
    });
  }, []);

  const handleFindCnpj = async (document: string) => {
    document = onlyNumbers(document);
    if (!document) {
      return;
    }
    if (!validCnpj(document)) {
      toast({
        title: "CNPJ inválido",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
      return;
    }

    setValidatedCnpj(true);
  };

  const { isLoading: isLoadingSave, mutateAsync: nextOnboardingMutate } =
    useMutation(["nextOnboarding"], (data: NextOnboarding) =>
      nextOnboarding(id, data)
    );

  const handleNext = (data: any) => {
    nextOnboardingMutate({
      step: "company",
      company: {
        ...data,
        document: onlyNumbers(data.document),
        phone: onlyNumbers(data.phone),
      },
    }).then(() => {
      history.push(`/auth/register/holder?id=${id}`);
    });
  };

  return (
    <DefaultAuth
      illustrationBackground={getVariable("banner")}
      image={getVariable("banner")}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        my={{ base: "auto", lg: "150" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "30px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "0", md: "4vh" }}
        flexDirection="column"
      >
        <Box w="full" alignItems="center" display="flex" flexDirection="column">
          <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
            Dados da sua empresa
          </Heading>
          {isLoading && <p>Carregando...</p>}
          {!isLoading && (
            <VStack spacing={4} alignItems="flex-start" w="100%">
              <InputMaskForm
                isDisabled={validatedCnpj}
                control={control}
                rules={{ required: "Campo obrigatório" }}
                placeholder="12.345.678/0001-99"
                label="CNPJ"
                mask="99.999.999/9999-99"
                name="document"
                type="tel"
                onBlur={(e) => handleFindCnpj(e.target.value)}
              />
              <InputForm
                control={control}
                label="Nome"
                name="name"
                isDisabled={!validatedCnpj}
                type="text"
                placeholder="Informe o nome da empresa"
                rules={{ required: "Campo obrigatório" }}
              />
              <InputForm
                control={control}
                label="Nome fantasia"
                name="secondName"
                isDisabled={!validatedCnpj}
                type="text"
                rules={{ required: "Campo obrigatório" }}
                placeholder="Informe o nome fantasia da empresa"
              />
              <InputForm
                control={control}
                label="Email"
                name="email"
                rules={{ required: "Campo obrigatório" }}
                isDisabled={!validatedCnpj}
                placeholder="Informe o email da empresa"
                type="email"
              />
              <InputMaskForm
                control={control}
                label="Telefone"
                placeholder="Informe o telefone da empresa"
                mask="(99) 99999-9999"
                rules={{ required: "Campo obrigatório" }}
                isDisabled={!validatedCnpj}
                name="phone"
                type="tel"
              />
              <CustomButton
                isLoading={isLoadingSave}
                onClick={handleSubmit(handleNext)}
                variant="solid"
              >
                Continuar
              </CustomButton>
            </VStack>
          )}
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default CompanyRegister;
