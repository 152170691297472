import { Box, Flex, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { maskDocument } from "utils/formatters";
import { maskCurrency } from "utils/number";
import { DateTime } from "luxon";
import { WithdrawSulcredi } from "..";
import { getVariable } from "whitelabel";

const ComprovanteImpressao = ({ data: dataWithdraw }, ref) => {
  const dataSulcredi = dataWithdraw.withdrawSulcredi as WithdrawSulcredi;

  if (!dataSulcredi) {
    return <></>;
  }

  return (
    <Box
      maxHeight="800px"
      minW="800px"
      maxW="800px"
      w={`100%`}
      ref={ref}
      px={3}
      backgroundColor={`#fff`}
    >
      <Flex flexDirection={`column`} w={`100%`} alignItems={`center`}>
        <VStack spacing={3} pt={`10px`} mb="5px">
          <Image
            cursor="pointer"
            alt=""
            src={getVariable("logo")}
            maxWidth={64}
            maxHeight={20}
          />
        </VStack>
        <Text
          color={`brand.500`}
          fontSize={{ sm: `lg`, md: `xl`, lg: `lg` }}
          fontWeight="bold"
          alignItems={`center`}
        >
          Comprovante Transferência
        </Text>
        {dataSulcredi?.updatedAt && (
          <Text fontWeight={700} fontSize={12} mt={3} color={`#666`}>
            {DateTime.fromISO(dataSulcredi.updatedAt).toFormat(
              "dd/MM/yyyy HH:mm:ss"
            )}
          </Text>
        )}
      </Flex>
      <SimpleGrid
        w={`100%`}
        my={10}
        py={10}
        columns={1}
        spacing={4}
      >
        <SimpleGrid columns={2} spacing={2}>
          <Box>
            <Text fontSize={10} color={`#999`}>
              Status
            </Text>
            <Text color={`#333`} fontWeight={700} fontSize={14}>
              {dataSulcredi?.status === 2 ? "Pago" : "Pendente"}
            </Text>
          </Box>

          <Box>
            <Text fontSize={10} color={`#999`}>
              Tipo
            </Text>
            <Text color={`#333`} fontWeight={700} fontSize={14}>
              {`Débito`}
            </Text>
          </Box>
          <Box>
            <Text fontSize={10} color={`#999`}>
              Tipo
            </Text>
            <Text color={`#333`} fontWeight={700} fontSize={14}>
              {dataSulcredi.type === "PIX" ? "PIX" : "BOLETO"}
            </Text>
          </Box>
          {dataSulcredi.pixKey && (
            <Box>
              <Text fontSize={10} color={`#999`}>
                Chave Pix
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataSulcredi.pixKey}
              </Text>
            </Box>
          )}

          <Box>
            <Text fontSize={10} color={`#999`}>
              Valor
            </Text>
            <Text color={`#333`} fontWeight={700} fontSize={14}>
              {maskCurrency(Math.abs(Number(dataWithdraw?.amount)))}
            </Text>
          </Box>

          {dataWithdraw?.updatedAt && (
            <Box>
              <Text fontSize={10} color={`#999`}>
                Liquidação
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {DateTime.fromISO(dataSulcredi?.updatedAt).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </Text>
            </Box>
          )}

          <Box>
            <Text fontSize={10} color={`#999`}>
              Descrição
            </Text>
            <Text color={`#333`} fontWeight={700}>
              {dataWithdraw.description || `---`}
            </Text>
          </Box>

          <Box>
            <Text fontSize={10} color={`#999`}>
              Autenticação
            </Text>
            <Text color={`#333`} fontWeight={700} fontSize={14}>
              {dataSulcredi?.endToEndId}
            </Text>
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={2} spacing={10}>
          <Box>
            <Text mt={2} color={`#ccc`} fontWeight={700} fontSize={15}>
              QUEM PAGOU
            </Text>

            <Box>
              <Text fontSize={10} color={`#999`}>
                Nome
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataSulcredi.debtorAccount.name}
              </Text>
            </Box>

            <Box>
              <Text fontSize={10} color={`#999`}>
                Instituição
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataSulcredi.debtorAccount.ispb +
                  " - " +
                  dataSulcredi.debtorAccount.ispbName}
              </Text>
            </Box>

            <Box>
              <Text fontSize={10} color={`#999`}>
                CPF / CNPJ
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {maskDocument(dataSulcredi.debtorAccount.document)}
              </Text>
            </Box>
          </Box>

          <Box>
            <Text mt={2} color={`#ccc`} fontWeight={700} fontSize={15}>
              QUEM RECEBEU
            </Text>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Nome
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataSulcredi.creditorAccount.name}
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Instituição
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataSulcredi.creditorAccount.ispb +
                  " - " +
                  dataSulcredi.creditorAccount.ispbName}
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                CPF / CNPJ
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {maskDocument(dataSulcredi.creditorAccount.document)}
              </Text>
            </Box>
          </Box>
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
};

export default forwardRef(ComprovanteImpressao);
