// Chakra imports
import { Badge, Box, Flex, IconButton, SimpleGrid } from "@chakra-ui/react";

import { FC, useMemo } from "react";
import { findAllCharges } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import TableComponent from "components/TableComponent";
import { FaEye } from "react-icons/fa";
import CreateCharge from "./CreateCharge";
import { ChargeType, ItemChargeStatus } from "types/Charge";
import PullToRefresh from "react-simple-pull-to-refresh";
import { queryClient } from "index";

export enum ChargeStatus {
  PENDING = "PENDING",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  CANCELED = "CANCELED",
}

export enum CreditCardStatus {
  PENDING = "PENDING",
  PAID = "PAID",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
  CONFIRMED = "CONFIRMED",
  AUTHORIZED = "AUTHORIZED",
}

export const CreditCardStatusRender = (status: CreditCardStatus) => {
  if (status === CreditCardStatus.PENDING)
    return <Badge colorScheme="orange">Pendente</Badge>;
  if (
    [
      CreditCardStatus.AUTHORIZED,
      CreditCardStatus.PAID,
      CreditCardStatus.CONFIRMED,
    ].includes(status)
  )
    return <Badge colorScheme="green">Pago</Badge>;
  if (status === CreditCardStatus.CANCELED)
    return <Badge colorScheme="red">Cancelado</Badge>;
  if (status === CreditCardStatus.REFUNDED)
    return <Badge colorScheme="red">Devolvido</Badge>;
  else return <Badge colorScheme="red">Erro</Badge>;
};

export const StatusRender: FC<{
  status: ChargeStatus;
}> = ({ status }) => {
  if (status === ChargeStatus.PENDING)
    return <Badge colorScheme="orange">Pendente</Badge>;
  if (status === ChargeStatus.PAID)
    return <Badge colorScheme="green">Pago</Badge>;
  if (status === ChargeStatus.REFUNDED)
    return <Badge colorScheme="blue">Devolvido</Badge>;
  if (status === ChargeStatus.PARTIALLY_REFUNDED)
    return <Badge colorScheme="blue">Devolvido Parcialmente</Badge>;
  if (status === ChargeStatus.CANCELED)
    return <Badge colorScheme="red">Cancelado</Badge>;
};

export const BilletPixStatusRender = (status: ItemChargeStatus) => {
  if (status === ItemChargeStatus.PENDING)
    return <Badge colorScheme="orange">Pendente</Badge>;
  if (status === ItemChargeStatus.FINISHED)
    return <Badge colorScheme="green">Pago</Badge>;
  if (status === ItemChargeStatus.CANCELED)
    return <Badge colorScheme="red">Cancelado</Badge>;
  if (status === ItemChargeStatus.REFUNDED)
    return <Badge colorScheme="red">Devolvido</Badge>;
  else return <Badge colorScheme="red">Erro</Badge>;
};

export default function Charges() {
  const history = useHistory();
  const columns = useMemo(
    () =>
      [
        {
          Header: "Cliente",
          accessor: "CustomerClient.name",
        },
        {
          Header: "Descrição",
          accessor: "description",
        },
        {
          Header: "Status",
          filter: true,
          values: [
            {
              value: ChargeStatus.PAID,
              label: "Pago",
            },
            {
              value: ChargeStatus.PENDING,
              label: "Pendente",
            },
            {
              value: ChargeStatus.CANCELED,
              label: "Cancelado",
            },
          ],
          accessor: "status",
          Cell: ({ value }: { value: ChargeStatus }) => {
            return <StatusRender status={value} />;
          },
        },
        {
          Header: "Tipo",
          accessor: "chargeType",

          Cell: ({ value }: { value: ChargeType }) => {
            return ChargeType[value];
          },
        },
        {
          Header: "Valor",
          accessor: "amount",
          align: "right",
          Cell: ({ value }) => {
            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },

        {
          accessor: "createdAt",
          Header: "Data",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <Flex align="center" justify="flex-start" w="100%">
      <IconButton
        aria-label="Apertar"
        rounded="md"
        size="sm"
        variant="outline"
        icon={<FaEye />}
        onClick={() => history.push(`/access/charge/${row.id}`)}
      />
    </Flex>
  );

  const handleRefresh = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["findAllCharges"],
    });
  };

  // Chakra Color Mode
  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <Box>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <TableComponent
            label="Cobranças"
            columnsData={columns}
            right={<CreateCharge />}
            filterable={["customerId", "createdAt"]}
            queryFn={findAllCharges}
            queryKey="findAllCharges"
            actions={actions}
          />
        </SimpleGrid>
      </Box>
    </PullToRefresh>
  );
}
