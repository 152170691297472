import { Box, Card, Heading, Text, useToast, VStack } from "@chakra-ui/react";
import Enable2FA from "../Enable2FA";
import ChangePasswordCustomer from "./ChangePasswordCustomer";
import DeleteAccount from "./DeleteAccount";
import { useQuery } from "@tanstack/react-query";
import {
  accountFeatures,
  changeFeatureNotification,
} from "services/api.service";
import EnableSafeCode from "./EnableSafeCode";
import CustomButton from "components/CustomButton";

const AccountSettings = () => {
  const { data, refetch } = useQuery(["features"], accountFeatures);

  const toast = useToast();

  const handleNotifications = async (type: "push" | "email") => {
    await changeFeatureNotification(type).then(() => {
      refetch();
      toast({
        title: "Sucesso",
        description: `Notificações por ${type} ativadas com sucesso`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <Card flexDirection="column" w="100%" p={2}>
      <Heading fontSize={18}>Sobre a conta</Heading>
      <Text fontWeight="bold">Segurança</Text>
      <VStack spacing={4} mt={5} align="start">
        <Card flexDirection="column" w="100%" p={2}>
          <Text fontWeight={"bold"}>Senha</Text>
          <Box>
            <ChangePasswordCustomer />
          </Box>
        </Card>
        <Card flexDirection="column" w="100%" p={2}>
          <Text fontWeight={"bold"}>Notificações</Text>
          <VStack>
            <Text fontWeight={"bold"}>Notificações por e-mail</Text>
            <Text>
              Receba notificações de transações, saques e depósitos em sua
              conta.
            </Text>
            <CustomButton onClick={() => handleNotifications("email")}>
              {data?.emailsEnabled ? "Desativar" : "Ativar"}
            </CustomButton>
          </VStack>
          <VStack>
            <Text fontWeight={"bold"}>Notificações por Push</Text>
            <Text>
              Receba notificações de transações, saques e depósitos em sua
              conta.
            </Text>
            <CustomButton onClick={() => handleNotifications("push")}>
              {data?.notificationsEnabled ? "Desativar" : "Ativar"}
            </CustomButton>
          </VStack>
        </Card>
        <Card flexDirection="column" w="100%" p={2}>
          <Text fontWeight={"bold"}>Senha mestre</Text>A senha mestre é uma
          camada extra de segurança para sua conta. Será solicitada sempre que
          você realizar uma transação de risco.
          <Box>
            {data && <EnableSafeCode isEnabled={data?.masterPasswordEnabled} />}
          </Box>
        </Card>
        <Card flexDirection="column" w="100%" p={2}>
          <Text fontWeight={"bold"}>2FA</Text>A autenticação de dois fatores é
          uma camada extra de segurança para autenticar sua conta.
          <Box>
            {data && !data?.twoFaEnabled && <Enable2FA />}{" "}
            {data && data?.twoFaEnabled && (
              <Text>2FA de autenticação ATIVADO</Text>
            )}
          </Box>
        </Card>
        <DeleteAccount />
      </VStack>
    </Card>
  );
};

export default AccountSettings;
