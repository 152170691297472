import { Text } from "@chakra-ui/react";
import React from "react";

const RecaptchaAlert = () => {
  return (
    <Text
      mt="20px"
      color="gray.400"
      fontSize="sm"
      textAlign={"center"}
    >
      Este site é protegido pelo reCAPTCHA e a{" "}
      <a href="https://policies.google.com/privacy">Política de Privacidade</a>{" "}
      e <a href="https://policies.google.com/terms">Termos de Serviço</a> se
      aplicam.
    </Text>
  );
};

export default RecaptchaAlert;
