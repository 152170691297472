// Chakra imports
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { getVariable } from "whitelabel";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import CustomButton from "components/CustomButton";
import {
  findOnboarding,
  NextOnboarding,
  nextOnboarding,
} from "services/api.service";
import { FaUpload } from "react-icons/fa";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { useMutation, useQuery } from "@tanstack/react-query";

function DocumentsOnboarding() {
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const id = params.get("id");
  const history = useHistory();
  const toast = useToast();
  const textColor = useColorModeValue("brand.700", "white");
  const [frontImage, setFrontImage] = useState<{
    file: string;
    base64: string;
  }>();
  const [backImage, setBackImage] = useState<{
    file: string;
    base64: string;
  }>();

  const [selfie, setSelfie] = useState<{
    file: string;
    base64: string;
  }>();

  const { data } = useQuery(["onboarding", id], () => findOnboarding(id), {
    enabled: !!id,
  });

  const handleSelect = async (setFile: Function) => {
    if (data.backImageUrl && data.frontImageUrl && data.selfieImageUrl) {
      toast({
        title: "Error",
        description: "Você já enviou os documentos",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
      return;
    }
    if (Capacitor.isNativePlatform()) {
      const permission = await Camera.checkPermissions();
      if (permission.camera !== "granted") {
        const permission = await Camera.requestPermissions({
          permissions: ["camera"],
        });
        if (permission.camera !== "granted") {
          toast({
            title: "Error",
            description:
              "Você precisa permitir o acesso a câmera para continuar",
            status: "error",
            duration: 5000,
            position: "top",
            isClosable: true,
          });
          return;
        }
      }
    }

    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
    });

    setFile(image);
  };

  const { isLoading: isLoadingSave, mutateAsync: nextOnboardingMutate } =
    useMutation(["nextOnboarding"], (data: NextOnboarding) =>
      nextOnboarding(id, data)
    );

  const handleNext = async () => {
    await nextOnboardingMutate({
      step: "documents",
      documents: {
        frontImage,
      },
    });
    await nextOnboardingMutate({
      step: "documents",
      documents: {
        backImage,
      },
    });
    nextOnboardingMutate({
      step: "documents",
      documents: {
        selfie: selfie,
      },
    }).then((data) => {
      history.push(`/auth/register/credentials?id=${id}`);
    });
  };

  return (
    <DefaultAuth
      illustrationBackground={getVariable("banner")}
      image={getVariable("banner")}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        my={{ base: "auto", lg: "150" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "30px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "0", md: "4vh" }}
        flexDirection="column"
      >
        <Box w="full" alignItems="center" display="flex" flexDirection="column">
          <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
            Documentos
          </Heading>
          <VStack spacing={4} alignItems="flex-start" w="100%">
            <Box
              p={5}
              border="1px"
              width="100%"
              borderColor={
                frontImage || data?.frontImageUrl ? "green.500" : "red.200"
              }
              borderRadius="md"
              display="flex"
              flexDirection="column"
              onClick={() => handleSelect(setFrontImage)}
              alignItems="center"
            >
              <Heading size="sm">Frente do documento</Heading>
              <FaUpload></FaUpload>
            </Box>

            <Box
              p={5}
              border="1px"
              width="100%"
              borderColor={
                backImage || data?.backImageUrl ? "green.500" : "red.200"
              }
              borderRadius="md"
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={() => handleSelect(setBackImage)}
            >
              <Heading size="sm">Verso do documento</Heading>
              <FaUpload></FaUpload>
            </Box>

            <Box
              p={5}
              border="1px"
              width="100%"
              borderColor={
                selfie || data?.selfieImageUrl ? "green.500" : "red.200"
              }
              borderRadius="md"
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={() => handleSelect(setSelfie)}
            >
              <Heading size="sm">Selfie com documento</Heading>
              <FaUpload></FaUpload>
            </Box>

            <CustomButton
              onClick={handleNext}
              variant="solid"
              isLoading={isLoadingSave}
              isDisabled={
                data?.frontImageUrl &&
                data?.backImageUrl &&
                data?.selfieImageUrl
                  ? false
                  : !(frontImage && backImage && selfie)
              }
            >
              Continuar
            </CustomButton>
          </VStack>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default DocumentsOnboarding;
