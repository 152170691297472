// Chakra imports
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { getVariable } from "whitelabel";
import InputMaskForm from "components/InputMask";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { onlyNumbers } from "utils/string";
import { useEffect, useState } from "react";
import InputForm from "components/InputForm";
import CustomButton from "components/CustomButton";
import {
  findCep,
  findOnboarding,
  NextOnboarding,
  nextOnboarding,
} from "services/api.service";
import { useMutation } from "@tanstack/react-query";

interface AddressForm {
  cep: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
}

function AddressRegister() {
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const id = params.get("id");
  const [cepId, setCepId] = useState<number>();
  const history = useHistory();

  const textColor = useColorModeValue("brand.700", "white");
  const [validatedCep, setValidateCEP] = useState(false);
  const { control, handleSubmit, setValue, setError, clearErrors } =
    useForm<AddressForm>();
  const toast = useToast();
  const { isLoading, mutateAsync } = useMutation([], () => findOnboarding(id));
  useEffect(() => {
    mutateAsync().then((response) => {
      if (response.address) {
        setValidateCEP(true);
        setValue("cep", response.address.cep.cep);
        setValue("address", response.address.address);
        setValue("number", response.address.number);
        setValue("complement", response.address.complement);
        setValue("neighborhood", response.address.neigherhood);
        setValue("city", response.address.cep.City.name);
        setValue("state", response.address.cep.state.name);
      }
    });
  }, [mutateAsync, setValue]);

  const handleCep = async (cep: string) => {
    cep = onlyNumbers(cep);
    if (!cep) {
      return;
    }

    findCep(cep)
      .then((item) => {
        setValidateCEP(true);
        clearErrors("cep");
        setValue("address", item.address);
        setValue("neighborhood", item.neigherhood);
        setValue("city", item.City.name);
        setValue("state", item.state.name);
        setCepId(item.id);
      })
      .catch(() => {
        setError("cep", {
          type: "manual",
          message: "CEP não encontrado",
        });

        toast({
          title: "CEP não encontrado",
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      });
  };
  const { isLoading: isLoadingSave, mutateAsync: nextOnboardingMutate } =
    useMutation(["nextOnboarding"], (data: NextOnboarding) =>
      nextOnboarding(id, data)
    );

  const handleNext = (data: AddressForm) => {
    nextOnboardingMutate({
      step: "address",
      address: {
        ...data,
        cep: onlyNumbers(data.cep),
        cepId,
      },
    }).then((data) => {
      history.push(`/auth/register/documents?id=${id}`);
    });
  };

  return (
    <DefaultAuth
      illustrationBackground={getVariable("banner")}
      image={getVariable("banner")}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        my={{ base: "auto", lg: "150" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "30px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "0", md: "4vh" }}
        flexDirection="column"
      >
        <Box w="full" alignItems="center" display="flex" flexDirection="column">
          <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
            Dados do endereço
          </Heading>
          <VStack spacing={4} alignItems="flex-start" w="100%">
            {isLoading && <p>Carregando...</p>}

            {!isLoading && (
              <>
                <InputMaskForm
                  isDisabled={validatedCep}
                  control={control}
                  rules={{ required: "Campo obrigatório" }}
                  placeholder="12345-000"
                  label="CEP"
                  mask="99999-999"
                  name="cep"
                  type="tel"
                  onBlur={(e) => handleCep(e.target.value)}
                />
                <InputForm
                  control={control}
                  label="Rua"
                  name="address"
                  isDisabled={!validatedCep}
                  type="text"
                  placeholder="Informe seu endereço"
                  rules={{ required: "Campo obrigatório" }}
                />

                <InputForm
                  control={control}
                  label="Número"
                  name="number"
                  rules={{ required: "Campo obrigatório" }}
                  isDisabled={!validatedCep}
                  placeholder="Informe o número"
                  type="text"
                />

                <InputForm
                  control={control}
                  label="Complemento"
                  name="complement"
                  rules={{ required: "Campo obrigatório" }}
                  isDisabled={!validatedCep}
                  placeholder="Informe o complemento"
                  type="text"
                />

                <InputForm
                  control={control}
                  label="Bairro"
                  name="neighborhood"
                  rules={{ required: "Campo obrigatório" }}
                  isDisabled={!validatedCep}
                  placeholder="Informe o bairro"
                  type="text"
                />

                <InputForm
                  control={control}
                  label="Cidade"
                  name="city"
                  rules={{ required: "Campo obrigatório" }}
                  isDisabled={true}
                  placeholder="Informe a cidade"
                  type="text"
                />

                <InputForm
                  control={control}
                  label="Estado"
                  name="state"
                  rules={{ required: "Campo obrigatório" }}
                  isDisabled={true}
                  placeholder="Informe o estado"
                  type="text"
                />
              </>
            )}

            <CustomButton
              isLoading={isLoadingSave}
              onClick={handleSubmit(handleNext)}
              variant="solid"
            >
              Continuar
            </CustomButton>
          </VStack>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default AddressRegister;
